<template>
  <AuthToWebsite
    v-if="$store.state.window.width < 1080"
  />

  <div class="sign-in">
    <div class="sign-in__tabs">
      <TabButton
        :text="$t('views.auth.sign-up.sign-up')"
        :value="'SignUp'"
        :active="$route.name"
        @onclick="$router.push({ name: 'SignUp' })"
      />

      <TabButton
        :text="$t('views.auth.sign-in.sign-in')"
        :value="'SignIn'"
        :active="$route.name"
        @onclick="$router.push({ name: 'SignIn' })"
      />
    </div>

    <h2>
      {{ $t('views.auth.sign-in.sign-in') }}
    </h2>

    <form @submit.prevent="submit">
      <InputEmail
        :placeholder="`${$t('views.auth.sign-in.email')}*`"
        :errors="form.errors.email"
        :topInfo="form.info.email"
        name="email"
        v-model="form.email"
      />

      <InputPassword
        :placeholder="`${$t('views.auth.sign-in.password')}*`"
        :errors="form.errors.password"
        :auto-focus="passwordAutoFocus"
        name="password"
        v-model="form.password"
      />

      <div class="sign-in__checkbox-flex">
        <label
          for="check1"
          class="sign-in__checkbox-flex__label"
          :class="{
            active: form.check1
          }"
        >
          <div class="sign-in__checkbox-flex__label__point" />

          {{ $t('views.auth.sign-in.remember-me') }}
        </label>
        <input
          type="checkbox"
          id="check1"
          v-model="form.check1"
          class="sign-in__checkbox-flex__label-hide"
        >

        <RouterLink
          :to="{
            name: 'ForgotPassword',
            query: form.email.length > 0 ? { email: form.email } : {}
          }"
          class="sign-in__checkbox-flex__link"
        >
          {{ $t('views.auth.sign-in.forgot-password') }}
        </RouterLink>
      </div>

      <BlueButton
        class="sign-in__button"
        type="submit"
        :loading="loading"
        :text="$t('views.auth.sign-in.sign-in')"
        :disabled="loading"
      />
    </form>

    <RouterLink
      :to="{ name: 'SignUp' }"
      class="sign-in__link"
    >
      {{ $t('views.auth.sign-in.sign-up') }}
    </RouterLink>
  </div>

  <AuthSupport />
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import AuthToWebsite from '@/ui/AuthToWebsite.vue'
import TabButton from '@/ui/buttons/TabButton.vue'
import InputEmail from '@/ui/inputs/Email.vue'
import InputPassword from '@/ui/inputs/Password.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import AuthSupport from '@/ui/AuthSupport.vue'

export default {
  components: {
    AuthToWebsite,
    TabButton,
    InputEmail,
    InputPassword,
    BlueButton,
    AuthSupport
  },
  data () {
    return {
      loading: false,
      v: useVuelidate(),
      form: {
        email: '',
        password: '',
        check1: false,

        passwordAutoFocus: false,

        errors: {
          email: [],
          password: []
        },
        info: {
          email: []
        }
      }
    }
  },
  validations () {
    return {
      form: {
        email: { required, email },
        password: { required }
      }
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }

    if (this.$route.query.emailTaken === 'true') {
      this.passwordAutoFocus = true
      this.$route.query.email && this.form.info.email.push(
        this.$t('info.email-taken', { value: this.$route.query.email }))
    }
  },
  methods: {
    async submit () {
      this.loading = true
      this.v.form.$touch()
      this._setErrors()

      if (this._countError() === 0) {
        const formData = new FormData()
        formData.append('email', this.form.email)
        formData.append('password', this.form.password)
        formData.append('fingerprint_hash', this.$store.state.user?.fingerprint || {})

        try {
          const resp = await this.$axios.post(
            '/auth/login', formData)

          if (resp.status === 200) {
            if (window?.PasswordCredential && this.form.check1) {
              // eslint-disable-next-line no-undef
              const passwordCredential = new PasswordCredential({
                id: this.form.email,
                password: this.form.password
              })

              navigator.credentials.store(passwordCredential)
            }

            this.$gtm.push({
              event: 'login',
              loginMethod: 'email'
            })

            await this.$auth.redirect({
              token: resp.data.access_token,
              isRefresh: this.form.check1
            })
          }
        } catch (error) {
          this.form.password = ''

          const { response: { data } } = error
          if (data.user_hash) {
            this.$router.push({ name: 'SignUpVerifyPhone', params: { hash: data.user_hash } })
          }

          if (error?.error === 'Unauthorized') {
            this.form.errors.password.push('errors.unauthorized')
          } else {
            throw new Error(error)
          }
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    _setErrors () {
      const errorsForm = this.v.form
      this.form.errors = {
        email: [],
        password: []
      }

      // Email
      if (errorsForm.email.required.$invalid) {
        this.form.errors.email.push('errors.required')
      }
      if (errorsForm.email.email.$invalid) {
        this.form.errors.email.push('errors.email')
      }

      // Password
      if (errorsForm.password.required.$invalid) {
        this.form.errors.password.push('errors.required')
      }
    },
    _countError () {
      const errors = this.form.errors
      var count = 0

      count += errors.email.length + errors.password.length
      return count
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in {
  width: 100%;
  max-width: 430px;
  padding: 20px 20px 30px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  .sign-in__tabs {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #9CA3B8;

    button {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h2 {
    margin: 0 0 20px;
    color: rgb(32, 37, 57);
    line-height: 100%;
    font-family: SemiBold;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .sign-in__tabs__input {
    height: 46px;
    width: 100%;
    background: #F0F2F8;
    border-radius: 5px;
  }

  .sign-in__checkbox-flex {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;

    .sign-in__checkbox-flex__label {
      width: 100%;
      display: flex;
      align-items: center;
      color: rgb(32, 37, 57);
      font-family: Medium;
      font-size: 12px;
      line-height: 100%;
      cursor: pointer;

      &:hover {
        .sign-in__checkbox-flex__label__point {
          border: 1px solid rgb(47, 87, 233);
        }
      }

      &.active {
        .sign-in__checkbox-flex__label__point {
          border: 4px solid rgb(47, 87, 233);
        }
      }

      .sign-in__checkbox-flex__label__point {
        height: 14px;
        width: 14px;
        min-width: 14px;
        margin-right: 6px;
        border-radius: 50%;
        background: white;
        border: 1px solid #787C8D;
        transition: .2s;
      }
    }

    .sign-in__checkbox-flex__label-hide {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 1px;
      opacity: 0;
    }

    .sign-in__checkbox-flex__link {
      white-space: nowrap;
      color: rgb(47, 87, 233);
      font-family: SemiBold;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .sign-in__link {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    color: rgb(47, 87, 233);
    font-family: SemiBold;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
  }
}
</style>
